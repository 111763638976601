.orderDetailsId {
    font-weight: 400;
    font-size: 144px;
    line-height: 120px;
    font-family: 'Iceland';
    text-align: center;
    color: #F2F2F3;
    text-shadow: 0px 0px 16px rgba(51, 51, 255, 0.25), 0px 0px 8px rgba(51, 51, 255, 0.25), 0px 4px 32px rgba(51, 51, 255, 0.5);
    margin-bottom: 32px;
}

.orderDetailsTitle {
    font-family: 'JetBrains Mono', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 60px;
    color: #F2F2F3;
}

.orderDetailsText {
    font-family: 'JetBrains Mono', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #F2F2F3;
    margin-bottom: 8px;
}

.orderDetailsWait {
    font-family: 'JetBrains Mono', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #8585AD;
}

.orderDetailsImage {
    display: block;
    margin: 0 auto 60px;
}