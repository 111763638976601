.container {
  margin: 180px auto;
}

.tips {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.tip {
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
  color: #4C4CFF;
}

.title {
  text-align: center;
  margin-bottom: 24px;
}

.form {
  display: grid;
  gap: 24px;
  padding: 24px 0 80px 0;
  justify-items: center;
}