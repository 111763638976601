.container {
  margin: 180px auto;
}

.tips {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.tip {
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
  color: #4C4CFF;
}

.error {
  color: #ff1919;
}
