.frame {
  display: flex;
  width: 64px;
  height: 64px;
  background: linear-gradient(to right, rgba(128, 26, 178, 1), rgba(76, 76, 255, 1));
  border-radius: 100px;
  position: relative;
}

.img {
  margin: auto;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background-color: #1C1C21;
  object-fit: cover;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background-color: rgb(28, 28, 33, 0.6);
}

.intersection {
  margin-right: -15px;
}
