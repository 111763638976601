.orderBlock {
  padding: 40px;
  margin: auto;
  width: 640px;
  text-align: start;
}

.modal {
  text-align: center;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orderDone {
  color: #00CCCC;
}

.ingredient {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ingredientCard {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}

.ingredientPriceBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
}

.bottomBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.orderDate {
  color: #8585AD;
}

.blockWithScroll {
  max-height: 312px;
  display: grid;
  gap: 16px;
  align-content: flex-start;
  overflow-y: scroll;
  padding: 0 24px 0 0;
}

.blockWithScroll::-webkit-scrollbar {
  width: 8px;
  /* ширина для вертикального скролла */
  background-color: #2F2F37;
}

/* ползунок скроллбара */
.blockWithScroll::-webkit-scrollbar-thumb {
  background-color: #8585AD;
}

.blockWithScroll::-webkit-scrollbar-thumb:hover {
  background-color: #6d6dac;

}
