.title {
  text-align: center;
  margin-bottom: 24px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
