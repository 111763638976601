.blockWithScroll {
  height: 860px;
  max-width: 844px;
  width: 100%;
  display: grid;
  gap: 24px;
  align-content: flex-start;
  overflow-y: scroll;
  padding: 0 8px 0 0;
  margin: 20px 0 0;
}

.blockWithScroll::-webkit-scrollbar {
  width: 8px;
  /* ширина для вертикального скролла */
  background-color: #2F2F37;
}

/* ползунок скроллбара */
.blockWithScroll::-webkit-scrollbar-thumb {
  background-color: #8585AD;
}

.blockWithScroll::-webkit-scrollbar-thumb:hover {
  background-color: #6d6dac;

}
