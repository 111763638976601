.ingredientDetailsImage {
    margin-bottom: 16px;
    text-align: center;
}

.ingredientDetailsTitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #F2F2F3;
    margin-bottom: 32px;
}

.ingredientDetailsOrganicSubstancesItem {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #8585AD;
    padding: 0 10px;
}

.ingredientDetailsOrganicSubstances {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
}

.ingredientDetailsImage {
    height: 240px;
    width: auto;
}