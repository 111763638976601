body {
  margin: 0;
  font-family: 'Jet Brains Mono', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.space {
    width: 40px;
}


h1 {
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
}

h2 {
  text-align: left;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #F2F2F3;
}

h3 {
  margin-top: 0;
  margin-bottom: 24px;
  text-align: left;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

li {
  list-style: none;
}

ul {
  padding-left: 0;
}