.table {
  height: 206px;
  width: 272px;
  text-align: left;
  color: #F2F2F3;
}

.tableList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 8px 0px;
}

.listElement {
  height: 24px;
  margin: 0;
  padding: 0;
}

.done {
  color: #00CCCC;
}
