.form {
  display: grid;
  gap: 24px;
  padding: 24px 0 80px 0;
  justify-items: end;
}

.buttons {
  display: flex;
}
