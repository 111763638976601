.burgerIngredientsCol {
    width: calc(50% - 20px);
    flex: 1;
}

.tabWrap {
    display: flex;
}

.tabContent {
    max-height: 756px;
    overflow-y: auto;
    composes: custom-scroll from global;
}

.ingredientsGroup {
    margin-bottom: 40px;
}
.ingredientsGroup::after {
    content: '';
    display: block;
    clear: both;
}

.tabContentItem {
    display: none;
}
.tabContentItem.active {
    display: block;
}