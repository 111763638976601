.appHeaderItem {
    margin-right: 8px;
    display: flex;
    align-items: center;
    border-radius: 40px;
    padding: 20px;
}
.appHeaderItemSvg {
    margin-right: 8px;
}

.logo {
    height: 50px;
}

.appHeaderNav {
    display: flex;
}


.appHeader {
    display: flex;
    justify-content: space-between;
    background: #1C1C21;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    padding: 16px 0;
    align-items: center;
  }