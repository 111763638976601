.modal {
    position: absolute;
    top: 10%;
    left: 50%;
    margin: 0 auto;
    transform: translateX(-50%);
    width: 720px;
    border-radius: 40px;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(76, 76, 255, 0.2);
    box-sizing: border-box;
    background: #1C1C21;
    z-index: 1;
}

.modalContent {
    position: relative;;
}

.wrapCloseIcon {
    position: absolute;
    right: 0;
    top: 0;
}

.modalHeader {
    
}