.orderCard {
  background: #1C1C21;
  list-style: none;
  text-decoration: none;
  color: #F2F2F3;
  border-radius: 40px;
  max-width: 844px;
  height: 246px;
  display: grid;
  align-items: center;
  padding: 24px;
  box-sizing: border-box;
  grid-template-areas:
    "number date"
    "name name"
    "status status"
    "ingredients total"
}

.number {
  margin: 0;
  grid-area: number;
  justify-self: start;
}

.date {
  margin: 0;
  grid-area: date;
  justify-self: end;
}

.name {
  margin: 0;
  grid-area: name;
  justify-self: start;
  max-width: 536px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.status {
  margin: 0;
  grid-area: status;
  justify-self: start;
}

.complete {
  color: #00CCCC;
}

.default {
  color: #F2F2F3;
}

.ingredients {
  margin: 0;
  grid-area: ingredients;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.total {
  margin: 0;
  grid-area: total;
  margin: auto 0 auto auto;
}
