.burgerConstructorCol {
    padding: 100px 16px 52px;
    border-top: 0;
    width: calc(50% - 20px);
    flex: 1;
}

.burgerConstructorContent {
    margin-bottom: 40px;
}

.burgerConstructorList {
    display: 'flex';
    flex-direction: 'column';
    gap: '10px';
}

.total {
    font-family: 'Iceland';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 36px;
    margin-right: 8px;
}
.total + svg {
    margin-right: 40px;
    width: 33px;
    height: 33px
}

.burgerConstructorData {
    max-height: 464px;
    overflow-y: auto;
    max-width: 536px;
    composes: custom-scroll from global;
    margin-left: auto;
}

.ingredient {
    display: flex;
    align-items: center;
}

.result {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.resultContent {
    display: flex;
    align-items: center;
}

.orderTotalPrice {
    font-weight: 400;
    font-size: 48px;
    line-height: 36px;
    color: #F2F2F3;
}

.orderWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.element {
    display: flex;
    margin: 16px 0;
    align-items: center;
}